import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {Button} from "src/components/button";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {ShevronRight} from "src/media/icons/custom/shevron/shevronRight";
import {Colors} from "src/utils/defaults/colors";
import routes from "src/utils/defaults/routes";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";
import useViewport from "../../../utils/zustandStores/primary/useViewport";
import {useEffect} from "react";

export default function MobileAccountSettings() {
    const navigate = useNavigate();
    const {user} = useUserHeader();
    const {width} = useViewport();
    const {user: tokenUser} = useTokenStore();

    useEffect(() => {
        if (width >= 768) navigate(routes.userPersonalDetails)
    }, [width]);

    return <div className={'flex flex-col px-5'}>

        <section className={'flex flex-col pt-10 border-b'}>

            <h1 className={'text-[#222222] text-[32px] leading-[36px] font-medium'}>Profile</h1>

            <section className={'flex py-6 gap-4 items-center'}>
                <Avatar className={'w-14 h-14 rounded-full cursor-pointer'}>
                    <AvatarImage src={(user && user.avatar && user.avatar !== '') ? user.avatar : undefined}/>
                    <AvatarFallback
                        className={'text-sm'}>{user ? getFirstLetters(user.firstName, user.lastName) : ''}</AvatarFallback>
                </Avatar>

                <div className={'flex flex-col gap-[2px]'}>

                    <div className={'text-[#222222] text-[18px] leading-[#28px] font-normal'}>{getString([
                        {element: user?.firstName, tag: ElementTag.name},
                        {element: user?.lastName, tag: ElementTag.name},
                    ])}
                    </div>

                    <div className={'flex gap-2 items-center text-[#717171] text-[14px] leading-[20px] font-normal'}>
                        {user?.companyName}
                        <DotIcon size={4}/>
                        {tokenUser && `${tokenUser.roles[0] + tokenUser.roles.slice(1).toLowerCase()}`}
                    </div>
                </div>
            </section>

            <div onClick={() => navigate(routes.userPersonalDetails)}
                 className={'py-4 flex items-center justify-between cursor-pointer'}>
                <div className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>Account details</div>
                <ShevronRight size={20} stroke={Colors.foreground}/>
            </div>
        </section>


        {
            (tokenUser && tokenUser.roles !== 'DRIVER') && <section className={'flex flex-col gap-0 border-b'}>
                <h3 className={'pt-6 pb-4 text-[#222222] text-[24px] leading-[32px] font-medium'}>Company settings</h3>

                <div onClick={() => navigate(routes.companyDetails)}
                     className={'py-4 flex items-center justify-between cursor-pointer text-[#222222] text-[16px] leading-[24px] font-normal'}>
                    <div>Company Details</div>
                    <ShevronRight size={20} stroke={Colors.foreground}/>
                </div>

                <div onClick={() => navigate(routes.companyPaymentsAndPayouts)}
                     className={'py-4 flex items-center justify-between cursor-pointer '}>
                    <div className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>Payments & Payouts</div>
                    <ShevronRight size={20} stroke={Colors.foreground}/>
                </div>
            </section>
        }

        <section className={'flex flex-col gap-0 pt-6'}>

            <h3 className={'pb-4 text-[#222222] text-[24px] leading-[32px] font-medium'}>Support</h3>

            <div
                className={'py-4 flex items-center justify-between cursor-pointer text-[#222222] text-[16px] leading-[24px] font-normal'}>
                <div>Contact us</div>
                <ShevronRight size={20} stroke={Colors.foreground}/>
            </div>

            <div
                className={'py-4 flex items-center justify-between cursor-pointer border-b text-[#222222] text-[16px] leading-[24px] font-normal'}>
                <div>FAQ's</div>
                <ShevronRight size={20} stroke={Colors.foreground}/>
            </div>
        </section>

        <Button onClick={() => {
            useTokenStore.getState().resetToken();
            navigate('/sign-in')
        }} className={'mt-8 text-base font-medium leading-[20px]'} size={'lg'}
                variant={'outline'}>Log out</Button>
    </div>
}