import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";


export async function getDocument(documentId: string) {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/document/${documentId}`), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: Blob = await res.blob();
            const doc = data.slice(0, data.size, "application/pdf")
            return doc
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }
}