import {md5} from 'js-md5';
import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {Member} from "src/utils/types/structures/member";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function postAvatar(binaryBlob: string) {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else {
        const byteString = atob(binaryBlob.split(',')[1]);
        const uint8_array = Uint8Array.from(byteString, c => c.charCodeAt(0));
        const md5Name = Math.random() + (new Date().getMilliseconds())
        const fileName = md5(md5Name.toString()) + '.png'

        let blob = new Blob([uint8_array], {type: 'image/png'});

        const formData = new FormData();
        formData.append('avatar', blob, fileName);

        try {
            const res: Response = await fetch(ApiHost + encodeURI(`api/v1/auth/avatar`), {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (res.ok) {
                const member: Member = await res.json()
                return member
            } else return await getDefinedError(res)

        } catch (e) {
            return otherError
        }
    }
}