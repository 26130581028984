import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import useTokenStore from "../zustandStores/primary/useTokenStore";

export async function getStripeDashboardLink() {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else {
        try {
            const res = await fetch(ApiHost + encodeURI(`api/v1/company/link`), {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (res.ok) {
                const data: { link: string } = await res.json();
                return data
            } else return await getDefinedError(res)
        } catch (e) {
            return otherError
        }
    }
}