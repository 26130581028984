import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DesktopDetailsHeader from "src/pages/dashboarSection/orderDetails/buttons/buttonLayout/desktopDetailsHeader";
import MobileDetailsHeader from "src/pages/dashboarSection/orderDetails/buttons/buttonLayout/mobileDetailsHeader";
import DetailsContentLayout from "src/pages/dashboarSection/orderDetails/sections/details/detailsContentLayout";
import {getImages} from "src/utils/apiCalls/getImages";
import {getItems} from "src/utils/apiCalls/getItems";
import {getOrderCustomItems} from "src/utils/apiCalls/orderDetails/customItems/getOrderCustomItems";
import {getOrder} from "src/utils/apiCalls/orderDetails/getOrder";
import {getOrderItems} from "src/utils/apiCalls/orderDetails/orderItems/getOrderItems";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";

import {ErrorMessage} from "src/utils/types/errorMessage";
import {CustomItem} from "src/utils/types/structures/customItem";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderImage} from "src/utils/types/structures/orderImage";
import {OrderItem} from "src/utils/types/structures/orderItem";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import AttachmentsContent from "./sections/attachments/attachmentsContent";
import InventoryContent from "./sections/inventory/inventoryContent";
import ButtonHandler from "./buttons/buttonStatusHandle/buttonHandler";
import MoreActionsHandler from "./buttons/buttonMoreActionsHandle/moreActionsHandler";

export enum OrderDetailsTabSections {
    details = 'DETAILS',
    inventory = 'INVENTORY',
    attachments = 'ATTACHMENTS'
}

function getTabIdentity(tab: string) {
    switch (true) {
        case (tab === 'details'):
            return OrderDetailsTabSections.details;

        case (tab === 'attachments'):
            return OrderDetailsTabSections.attachments;

        case (tab === 'inventory'):
            return OrderDetailsTabSections.inventory;

        default: {
            return OrderDetailsTabSections.details
        }
    }
}

export default function OrderDetailsLayout() {

    const [isHidden, setHidden] = useState<boolean>(false);
    const {scrollPos, width} = useViewport();

    const [currentActiveTab, setCurrentActiveTab] = useState<OrderDetailsTabSections>(OrderDetailsTabSections.details);
    const [order, setOrder] = useState<OrderDetails | undefined>(undefined);
    const navigate = useNavigate();
    const {initExtendedItems, setItems} = useOrderInventory()
    const {initStore} = useOrderAttachments();
    const {id, section} = useParams();


    async function initData() {
        if (id) {
            let currentOrderItems: OrderItem[] = []
            let currentCustomItems: CustomItem[] = []
            let updatedImages: OrderImage[] = []

            const res = await getOrder(id)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                setOrder(res)
            }

            const items = await getItems();
            if (isInstanceOf<ErrorMessage>(items, 'message')) {
                handleResponseError(items, () => navigate(routes.login))
            } else {
                setItems(items)
            }

            const orderItems = await getOrderItems(id);
            if (isInstanceOf<ErrorMessage>(orderItems, 'message')) {
                handleResponseError(orderItems, () => navigate(routes.login))
            } else {
                currentOrderItems = orderItems
            }

            const customItems = await getOrderCustomItems(id);
            if (isInstanceOf<ErrorMessage>(customItems, 'message')) {
                handleResponseError(customItems, () => navigate('/'))
            } else {
                currentCustomItems = customItems
            }

            initExtendedItems(currentOrderItems, currentCustomItems)

            const images = await getImages(id)
            if (isInstanceOf<ErrorMessage>(images, 'message')) {
                handleResponseError(images, () => navigate(routes.login))
            } else {
                updatedImages = images
            }
            initStore(updatedImages, id)
        }
    }

    useEffect(() => {
        if (section) {
            const activeTab = getTabIdentity(section)
            setCurrentActiveTab(activeTab);
        } else {
            setCurrentActiveTab(OrderDetailsTabSections.details)
        }
    }, [section]);


    useEffect(() => {
        if (id) initData()
    }, [id]);

    if (!order) return <></>

    return <div className="w-full h-full flex flex-col">
        {width < defaultDimensions.md ?
            <MobileDetailsHeader setOrder={setOrder} order={order} currentActiveTab={currentActiveTab}/> :
            <DesktopDetailsHeader setOrder={setOrder} order={order} currentActiveTab={currentActiveTab}/>}

        <section className={'grow px-padding'}>
            <DetailsContentLayout currentActiveTab={currentActiveTab} setOrder={setOrder} order={order}/>
            <InventoryContent
                className={`flex flex-col gap-6 ${currentActiveTab !== OrderDetailsTabSections.inventory ? 'hidden' : ''}`}
                order={order} setOrder={setOrder}/>
            <AttachmentsContent
                className={`flex-col gap-4 pt-4 ${currentActiveTab !== OrderDetailsTabSections.attachments ? 'hidden' : ''}`}/>
        </section>

        {width < defaultDimensions.md &&
            <section className={`w-full ${isHidden ? 'absolute bottom-0 left-0' : 'fixed bottom-0 left-0'} z-30 pt-2`}>
                <ul
                    className={`grid grid-cols-2 bg-background p-4 border-t gap-x-4`}>
                    <MoreActionsHandler order={order}/>
                    <ButtonHandler currentActiveTab={currentActiveTab} order={order} setOrder={setOrder}/>
                </ul>
            </section>}
    </div>
}