import React from "react";
import {Button} from "src/components/button";
import {ScrollArea} from "src/components/scrollArea";
import {Sheet, SheetContent} from "src/components/sheet";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";

export default function SheetWrapper() {

    const {isOpen, content, close, title} = useSheet();

    return <Sheet open={isOpen}>
        <SheetContent className={'font-inter p-0 flex flex-col gap-0 h-dvh w-full md:!w-[488px]'}>
            <section className={'flex-none sticky top-0 z-50 bg-white border-b'}>
                <div className={'flex h-[4.25rem] justify-between px-6 py-5 place-items-center'}>
                    <h1 className={'text-[#222222] text-[20px] leading-[28px] font-semibold'}>{title}</h1>
                    <Button variant={'ghost'} size={"iconFit"} className={'p-[0.375rem]'}
                            onClick={() => close()}><CrossIcon size={20} className={'fill-foreground'}/></Button>
                </div>
            </section>

            <ScrollArea className={'h-[calc(100dvh-4.25rem)]'}>
                {content}
            </ScrollArea>
        </SheetContent>
    </Sheet>
}