import {create} from 'zustand'

interface State {
    isLoading: boolean
}

interface Actions {
    setLoadingStart: () => void
    setLoadingEnd: () => void
}

const useLoadingScreen = create<State & Actions>(
    (set, get) => ({
        isLoading: false,

        setLoadingStart: () => {
            set(
                (state) => (
                    {
                        isLoading: true
                    }
                )
            )
        },

        setLoadingEnd: () => {
            set(
                (state) => (
                    {
                        isLoading: false
                    }
                )
            )
        },
    })
)

export default useLoadingScreen;
