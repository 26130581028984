import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import otherError from "../errorHandlers/otherError";

const fetcher = async (url: string) => {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) throw error
    else try {
        const res: Response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json"
            }
        })

        if (res.ok) {
            return res.json()
        } else {
            const definedError = await getDefinedError(res)
            throw definedError
        }
    } catch (e) {
        throw otherError
    }
}
export default fetcher