import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Header from "src/pages/dashboarSection/layout/header/header";
import useHeaderNavStore from "src/utils/zustandStores/primary/useHeaderNavStore";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import routes from "../../../utils/defaults/routes";
import useDialog, {DialogElementType} from "./popUps/dialog/useDialog";
import {handleResponseError} from "../../../utils/errorHandlers/handleResponseError";
import {getCompanyStatus} from "../../../utils/apiCalls/getCompanyStatus";
import {AccountStatus} from "../../../utils/enums/accountStatus";
import DialogWrapper from "./popUps/dialog/dialogWrapper";
import {getDocumentLink} from "../../../utils/apiCalls/getDocumentLink";

export default function LayoutSign() {
    const navigate = useNavigate();
    const {menuBar} = useHeaderNavStore();
    const {user, token, checkTokenValidity, resetToken} = useTokenStore()
    const {open} = useDialog()
    const [link, setLink] = useState<string | null>();
    const [params,] = useSearchParams()

    const checkActiveCompany = async () => {
        const {data, error} = await getCompanyStatus()
        if (error) {
            handleResponseError(error, () => {
                const contractId = params.get('doc')
                if (contractId) navigate(routes.login + `?doc=${contractId}`)
                else navigate(routes.login)
            })
        }
        if (data && data !== AccountStatus.active) open(DialogElementType.completeUnboarding, data, 'Complete your account')
    }

    const initDoc = async () => {
        const documentId = params.get('doc')
        if (documentId) {
            const {data, error} = await getDocumentLink(documentId)
            if (data) setLink(data.signLink)
        }
    }

    useEffect(() => {
        checkActiveCompany().then(res => initDoc())
    }, []);

    useEffect(() => {
        const contractId = params.get('doc')

        if (!user || !token) {
            resetToken()
            if (contractId) navigate(routes.login + `?doc=${contractId}`)
            else navigate(routes.login)
        } else {
            const isValid = checkTokenValidity()
            if (!isValid) {
                resetToken()
                if (contractId) navigate(routes.login + `?${contractId}`)
                else navigate(routes.login)
            }
        }
    }, [user, token]);

    return <div className={'h-dvh w-dvw flex flex-col overflow-x-clip'}>
        <DialogWrapper/>

        <div className={'h-full w-full flex-1'}>
            {link && (
                <iframe
                    id="prepare_page"
                    src={link}
                    height="100%"
                    width="100%"
                    className={"frame h-full w-full"}
                />
            )}
        </div>

        <Header isAtBottom={true}/>

        <ul
            className={`md:hidden flex-none grid ${(user && user.roles === 'DRIVER') ? 'grid-cols-3' : 'grid-cols-5'} bg-background py-4 border-t`}>
            {
                user && menuBar
                    .filter(el => el.mobile && el.permission.includes(user.roles))
                    .map(el =>
                        <li key={el.title}
                            onClick={() => navigate(el.route)}
                            className={`flex flex-col gap-1 cursor-pointer items-center px-[0.125rem] ${el.isActive ? 'text-foreground' : 'text-[#717171]'}`}>
                            {el.icon}
                            <p className={`${el.isActive ? 'text-[#222222] font-medium' : 'text-[#717171]'} text-[11px] leading-[13px]`}>{el.title}</p>
                        </li>
                    )
            }
        </ul>

    </div>
}