import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export type RegistrationForm = {
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    phoneNumber: string,
    DOT: string,
    MC: string,
    companySize: string,
    currentService: string,
    serviceArea: string,
}

export async function sendApplication(registrationData: RegistrationForm) {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else {
        try {
            const res = await fetch(ApiHost + encodeURI(`api/v1/application`), {
                method: 'POST',
                body: JSON.stringify({
                    firstName: registrationData.firstName,
                    lastName: registrationData.lastName,
                    email: registrationData.email,
                    companyName: registrationData.companyName,
                    phone: registrationData.phoneNumber,
                    location: '',
                    mc: registrationData.MC,
                    dot: registrationData.DOT,
                    companySize: registrationData.companySize,
                    serviceArea: registrationData.serviceArea,
                    serviceYouOffer: registrationData.currentService

                }),
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            if (res.ok) {
                const data: OrderDetails = await res.json();
                return data
            } else return await getDefinedError(res)

        } catch (e) {
            return otherError
        }
    }


}