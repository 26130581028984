import React from "react";
import useLoadingScreen from "../utils/zustandStores/useLoadingScreen";
import {ThreeDots} from "react-loader-spinner";

export const LoadingScreen = () => {
    const {isLoading} = useLoadingScreen()

    return <div
        className={`
      flex-1 fixed h-dvh w-dvw grid items-center justify-center bg-white
      ${isLoading ? "z-[100] bg-opacity-30 !overflow-clip" : ""}
      ${!isLoading ? "z-0 hidden" : ""}`}
    >
        <ThreeDots
            ariaLabel="three-dots-loading"
            color="#222222"
            height="40"
            radius="10"
            visible
            width="40"
            wrapperClass=""
            wrapperStyle={{}}
        />
    </div>
}