import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {CustomItem} from "src/utils/types/structures/customItem";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function updateOrderCustomItems(orderId: string, customItems: CustomItem[]) {
    const items = customItems.map(el => {
        if (el.id) {
            return {
                id: el.id,
                name: el.name,
                length: el.length,
                width: el.width,
                height: el.height,
                count: el.count,
                weight: el.weight,
                description: el.description,
                volume: el.volume,
            }
        } else return {
            id: null,
            name: el.name,
            length: el.length,
            width: el.width,
            height: el.height,
            count: el.count,
            weight: el.weight,
            description: el.description,
            volume: el.volume,
        }
    })

    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/order/customItems`), {
            method: 'PUT',
            body: JSON.stringify(
                {
                    orderId: orderId,
                    items: items
                }),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: CustomItem[] = await res.json();
            return data
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }

}