import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {OrderItem} from "src/utils/types/structures/orderItem";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function updateOrderItems(orderId: string, orderItems: OrderItem[]) {

    const items = orderItems.map(el => {
        return {
            id: el.id,
            itemId: el.itemId,
            count: el.count
        }
    })

    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/order/items`), {
            method: 'PUT',
            body: JSON.stringify(
                {
                    orderId: orderId,
                    items: items
                }),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: OrderItem[] = await res.json();
            return data
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }
}