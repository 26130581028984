import useTokenStore from "../zustandStores/primary/useTokenStore";
import {ApiHost} from "../defaults/apiHost";
import otherError from "../errorHandlers/otherError";

export async function getDocumentLink(documentId: string) {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return {data: null, error: otherError};
    else try {
        const res = await fetch(
            ApiHost + `api/v1/document/${documentId}/sign`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
                method: "GET",
            },
        );

        if (res.ok) {
            const data: { signLink: string } = await res.json();
            return {data: data, error: null};
        } else {
            const err = await res.json();
            return {data: null, error: err};
        }
    } catch (e) {
        return {data: null, error: otherError};
    }
}