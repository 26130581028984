import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "src/components/button";
import {Separator} from "src/components/separator";
import OrderDocuments
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/orderDocuments/orderDocuments";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {OrderDetailsTabSections} from "src/pages/dashboarSection/orderDetails/orderDetailsLayout";
import routes from "src/utils/defaults/routes";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import PaymentsContent from "../../../layout/popUps/sheet/content/orderDetails/payments/paymentsContent";
import Notes from "../../../layout/popUps/sheet/content/orderDetails/notes/notes";

interface Props {
    order: OrderDetails
    currentActiveTab: OrderDetailsTabSections
}

export default function Tabs({order, currentActiveTab}: Props) {
    const navigate = useNavigate()
    const {id} = useParams();
    const {open} = useSheet();

    return <div className={'flex gap-2'}>
        <Button
            onClick={() => {
                if (currentActiveTab !== OrderDetailsTabSections.details) {
                    navigate(`${routes.loads}/${id}/details`)
                }
            }}
            key='details'
            variant={"rounded"}
            size={"tab"}
            className={`
                        ${currentActiveTab === OrderDetailsTabSections.details && 'outline outline-foreground outline-2 outline-offset-[-2px] bg-[#F2F2F2]'} 
                        rounded-full !h-10`}
        >
            <span className={`text-[#222222] text-[14px] leading-[20px] font-medium`}>Details</span>
        </Button>

        <Button
            onClick={() => {
                if (currentActiveTab !== OrderDetailsTabSections.inventory) {
                    navigate(`${routes.loads}/${id}/inventory`)
                }
            }}
            key='inventory'
            variant={"rounded"}
            size={"tab"}
            className={`
                        ${currentActiveTab === OrderDetailsTabSections.inventory && 'outline outline-foreground outline-2 outline-offset-[-2px] bg-[#F2F2F2]'} 
                        rounded-full !h-10`}
        >
            <span className={`text-[#222222] text-[14px] leading-[20px] font-medium`}>Inventory</span>
        </Button>

        <Button
            onClick={() => {
                if (currentActiveTab !== OrderDetailsTabSections.attachments) {
                    navigate(`${routes.loads}/${id}/attachments`)
                }
            }}
            key='attachments'
            variant={"rounded"}
            size={"tab"}
            className={`
                        ${currentActiveTab === OrderDetailsTabSections.attachments && 'outline outline-foreground outline-2 outline-offset-[-2px] bg-[#F2F2F2]'} 
                        rounded-full !h-10`}
        >
            <span className={`text-[#222222] text-[14px] leading-[20px] font-medium`}>Attachments</span>
        </Button>


        <Separator orientation={'vertical'} className={'h-6 place-self-center'}/>

        <Button
            onClick={() => open('Documents', <OrderDocuments order={order}/>)}
            variant={'secondary'}
            className={'!h-10 rounded-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Documents</Button>

        <Button
            onClick={() => open('Payments', <PaymentsContent order={order}/>)}
            variant={'secondary'}
            className={'!h-10 rounded-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Payments</Button>

        <Button
            onClick={() => open('Notes', <Notes order={order}/>)}
            variant={'secondary'}
            className={'!h-10 rounded-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Notes</Button>
    </div>
}