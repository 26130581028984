import {ApiHost} from "src/utils/defaults/apiHost";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import {CurrentTabStatus} from "src/utils/enums/CurrentTabStatus";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {IData} from "src/utils/types/iData";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function getOrdersMyLoads(currentActive: CurrentTabStatus, page: number | null) {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else {
        const user = useTokenStore.getState().user
        let status = 'booked';
        let urlFilter = 'myLoads';

        switch (currentActive) {
            case CurrentTabStatus.all: {
                status = 'all'
                break
            }

            case CurrentTabStatus.inProgress: {
                status = 'inProgress'
                break
            }

            case CurrentTabStatus.completed: {
                status = 'completed'
                break
            }

            case CurrentTabStatus.booked: {
                status = 'booked'
                break
            }
        }

        if (user) {
            switch (user.roles) {
                case 'OWNER':
                case 'MANAGER': {
                    urlFilter = 'companyLoads'
                    break
                }

                case 'DRIVER': {
                    urlFilter = 'myLoads'
                    break
                }
            }
        }

        try {
            const res = await fetch(ApiHost + encodeURI(`api/v1/order/${urlFilter}/${status}?size=${defaultFilterValues.limit}&page=${page}`), {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            if (res.ok) {
                const data: IData<OrderDetails> = await res.json();
                return data
            } else return await getDefinedError(res)

        } catch (e) {
            return otherError
        }
    }
}