import React from "react";
import {Button} from "src/components/button";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import {InventoryStatus} from "src/utils/enums/inventoryStatus";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import UpdateItems from "../../../../layout/popUps/sheet/content/orderDetails/updateItems/updateItems";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function InventoryButtonSet({order, setOrder, isLoading, setLoading,}: Props) {
    const {open} = useSheet();
    const {setCurrentExtendedItems, previousExtendedItems} = useOrderInventory();
    const {width} = useViewport();

    if (order?.inventoryStatus === InventoryStatus.readOnly) {
        return null
    }

    return <Button
        onClick={() => {
            setCurrentExtendedItems(previousExtendedItems)
            open('Manage inventory', <UpdateItems setOrder={setOrder} order={order}/>)
        }}
        className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
    >Manage inventory</Button>
}