import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import {AccountStatus} from "../enums/accountStatus";
import otherError from "../errorHandlers/otherError";

export async function getCompanyStatus() {

    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return {data: null, error: otherError}
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/company/status`), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: AccountStatus = await res.json();
            return {status: data, error: null}
        } else {
            const err = await getDefinedError(res)
            return {data: null, error: err}
        }
    } catch (e) {
        return {data: null, error: otherError}
    }
}