import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {Item} from "src/utils/types/structures/item";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function getItems() {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/item`), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: Item[] = await res.json();
            return data
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }
}