import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {Member} from "src/utils/types/structures/member";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export type POSTMember = {
    firstName: string
    lastName: string
    email: string
    phone: string
    role: string
}

export async function createMember(data: POSTMember) {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/company/user`), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (res.ok) {
            const data: Member = await res.json();
            return data
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }
}