import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function onboarding() {
    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else {
        try {
            const res = await fetch(ApiHost + encodeURI(`api/v1/company/onboarding`), {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            if (res.ok) {
                const data: { link: string } = await res.json();
                return data
            } else return await getDefinedError(res)

        } catch (e) {
            return otherError
        }
    }
}