import React from "react";
import {Button} from "src/components/button";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import {ThreeDotsVertical} from "src/media/icons/custom/threeDotsVertical";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {Colors} from "src/utils/defaults/colors";
import getAddressPropertyProps from "src/utils/stringHandlers/orderDetailsStrings/getAddressPropertyProps";
import {getCleanAddress} from "src/utils/stringHandlers/orderDetailsStrings/getCleanAddress";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderFulfillmentStatus} from "../../../../../../utils/enums/orderFulfillmentStatus";
import {PopoverClose} from "@radix-ui/react-popover";
import ChangeOrderAddress from "../../../../layout/popUps/sheet/content/orderDetails/changeAddress/changeOrderAddress";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function StopsSection({order, setOrder}: Props) {
    const {open} = useSheet()

    return <section key={'stops-details'} className={'flex flex-col gap-4 py-6'}>
        <h2 key={'stops-title'} className={'text-lg font-semibold'}>Stops</h2>


        <section key={'origin'} className={'flex flex-col gap-2'}>

            <div className={'flex gap-2 items-center'}>
                <div className={'flex-none place-self-center p-2'}>
                    <DotIcon size={8} className={'fill-foreground'}/>
                </div>
                <h3 className={'text-[#717171] text-[16px] leading-[20px] font-medium'}>Origin</h3>
            </div>

            <div className={'flex gap-2'}>
                <div className={'w-[24px] grid'}>
                    <div className={'bg-foreground place-self-center h-full border-l border-foreground'}></div>
                </div>

                <div className={'flex justify-between w-full gap-4'}>
                    <div className={'flex-1 flex flex-col gap-2'}>
                        <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getCleanAddress(order.originAddress?.address)}</p>
                        <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getAddressPropertyProps(order.originAddress)}</p>
                    </div>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={'ghost'}
                                size={'iconFit'}
                                className={'w-fit h-fit p-[0.375rem] flex-none'}><ThreeDotsVertical size={20} stroke={Colors.foreground}/></Button>
                        </PopoverTrigger>
                        <PopoverContent align={'end'}  className="w-[124px] h-fit mt-1 p-[5px]">
                            <PopoverClose className={'w-full text-start'}>
                                <div onClick={() => {navigator.clipboard.writeText(order.originAddress?.address ?? '')}} className={'p-2 rounded-[6px] hover:bg-[#F5F5F5] w-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Copy</div>
                            </PopoverClose>

                            {order.fulfillmentStatus === OrderFulfillmentStatus.atPickup && <PopoverClose className={'w-full text-start'}>
                                <div onClick={() => order.originAddress && open('Address details', <ChangeOrderAddress order={order} setOrder={setOrder}  address={order.originAddress}/>)} className={'p-2 rounded-[6px] hover:bg-[#F5F5F5] w-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Edit</div>
                            </PopoverClose>}

                        </PopoverContent>
                    </Popover>
                </div>

            </div>
        </section>

        <section key={'destination'} className={'flex flex-col gap-2'}>
            <div className={'flex gap-2 items-center'}>
                <div className={'flex-none place-self-center fill-foreground p-2'}>
                    <SquareIcon size={8} className={'fill-foreground'}/>
                </div>

                <h3 className={'text-[#717171] text-[16px] leading-[20px] font-medium'}>Destination</h3>
            </div>

            <div className={'flex gap-2 w-full'}>
                <div className={'flex-none w-[24px] grid'}>

                </div>

                <div className={'flex justify-between w-full gap-4'}>
                    <div className={'flex-1 flex flex-col gap-2'}>
                        <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getCleanAddress(order.destinationAddress?.address)}</p>
                        <p className={'text-[#222222] text-[16px] leading-[24px] font-normal'}>{getAddressPropertyProps(order.destinationAddress)}</p>
                    </div>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={'ghost'}
                                size={'iconFit'}
                                className={'w-fit h-fit p-[0.375rem] flex-none'}><ThreeDotsVertical size={20} stroke={Colors.foreground}/></Button>
                        </PopoverTrigger>
                        <PopoverContent align={'end'}  className="w-[124px] h-fit mt-1 p-[5px]">
                            <PopoverClose className={'w-full text-start'}>
                                <div onClick={() => {navigator.clipboard.writeText(order.destinationAddress?.address ?? '')}} className={'p-2 rounded-[6px] hover:bg-[#F5F5F5] w-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Copy</div>
                            </PopoverClose>

                            {order.fulfillmentStatus === OrderFulfillmentStatus.atPickup && <PopoverClose className={'w-full text-start'}>
                                <div onClick={() => order.destinationAddress && open('Address details', <ChangeOrderAddress order={order} setOrder={setOrder}  address={order.destinationAddress}/>)} className={'p-2 rounded-[6px] hover:bg-[#F5F5F5] w-full text-[#222222] text-[14px] leading-[20px] font-medium'}>Edit</div>
                            </PopoverClose>}

                        </PopoverContent>
                    </Popover>
                </div>
            </div>
        </section>
    </section>
}