export const atOriginNotesPackingContent = [
    {title: 'Blanket wrapped', value: 'Blanket wrapped'},
    {title: 'Carrier packed', value: 'Carrier packed'},
    {title: 'Shrink wrapped', value: 'Shrink wrapped'},
    {title: 'Carrier created', value: 'Carrier created'},
    {title: 'Carrier disassembled', value: 'Carrier disassembled'},
    {title: 'Shipper disassembled', value: 'Shipper disassembled'},
    {title: 'Shipper packed', value: 'Shipper packed'},
]

export const atOriginConditions = [
    {title: 'Bent', value: 'Bent'},
    {title: 'Faded', value: 'Faded'},
    {title: 'Broken', value: 'Broken'},
    {title: 'Dented', value: 'Dented'},
    {title: 'Loose', value: 'Loose'},
    {title: 'Scratched', value: 'Scratched'},
    {title: 'Cracked', value: 'Cracked'},
    {title: 'Rusted', value: 'Rusted'},
    {title: 'Peeling', value: 'Peeling'},
    {title: 'No visible damage', value: 'No visible damage'},
]