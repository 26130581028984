import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import getSimpleString from "src/utils/stringHandlers/getSimpleString";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useOrderInventory, {ExtendedOrderItem} from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import getExtendedItemVolumeAndWeight
    from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemVolumeAndWeight";
import React from "react";
import {Button} from "../../../../../../components/button";
import {ThreeDotsVertical} from "../../../../../../media/icons/custom/threeDotsVertical";
import {Colors} from "../../../../../../utils/defaults/colors";
import useSheet from "../../../../layout/popUps/sheet/useSheet";
import EditSingleItem from "../../../../layout/popUps/sheet/content/orderDetails/updateItems/edit-single-item";
import useViewport from "../../../../../../utils/zustandStores/primary/useViewport";
import {defaultDimensions} from "../../../../../../utils/defaults/defaultDimensions";
import {
    getExtendedItemDestinationMetadata,
    getExtendedItemOriginMetadata, isDestinationMetadataPresent, isMetadatePresent
} from "../../../../../../utils/getItemMetadata";
import {OrderFulfillmentStatus} from "../../../../../../utils/enums/orderFulfillmentStatus";
import EditMetadataAtDelivery
    from "../../../../layout/popUps/sheet/content/orderDetails/updateItems/edit-metadata-atDelivery";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function InvetoryTableLongRow({setOrder, order}: Props) {
    const {items, previousExtendedItems} = useOrderInventory();
    const {width} = useViewport()
    const {open} = useSheet()

    const onItemClickHandle = (el: ExtendedOrderItem) => {
        if (order.fulfillmentStatus === OrderFulfillmentStatus.atPickup) {
            open('Edit item', <EditSingleItem data={el} setOrder={setOrder} order={order}/>)
        }

        if (order.fulfillmentStatus === OrderFulfillmentStatus.completeDelivery) {
            open('Edit item', <EditMetadataAtDelivery data={el} order={order}/>)
        }
    }

    return <Table>
        <TableHeader>
            <TableRow>
                <TableHead>Items</TableHead>
                <TableHead>Qty</TableHead>
                <TableHead>Volume</TableHead>
                {width < defaultDimensions.sm && <TableHead>Notes</TableHead>}

                {
                    width >= defaultDimensions.sm && <>
                        <TableHead>Notes at Pickup</TableHead>
                        <TableHead>Notes at Delivery</TableHead>
                    </>
                }
                <TableHead className={'w-12'}></TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {previousExtendedItems.map(el => <TableRow key={el.name}>
                <TableCell>{el.name}</TableCell>
                <TableCell>{getSimpleString(el.item.count)}</TableCell>
                <TableCell>{getExtendedItemVolumeAndWeight(el.item, items).volume} cu.ft</TableCell>
                {width < defaultDimensions.sm && <TableCell>
                    {
                        isMetadatePresent(el) &&
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0013 18.3327C14.6038 18.3327 18.3346 14.6018 18.3346 9.99935C18.3346 5.39685 14.6038 1.66602 10.0013 1.66602C5.3988 1.66602 1.66797 5.39685 1.66797 9.99935C1.66797 14.6018 5.3988 18.3327 10.0013 18.3327Z"
                                stroke={isDestinationMetadataPresent(el) ? '#007F5F' : '#717171'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.5 10.0007L9.16667 11.6673L12.5 8.33398" stroke={isDestinationMetadataPresent(el) ? '#007F5F' : '#717171'} strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </TableCell>}

                {
                    width >= defaultDimensions.sm && <>
                        <TableCell className={'truncate'}>{getExtendedItemOriginMetadata(el)}</TableCell>
                        <TableCell className={'truncate'}>{getExtendedItemDestinationMetadata(el)}</TableCell>
                    </>
                }

                <TableCell className={'p-0 w-12 min-w-12 items-center'}>
                    <Button
                        onClick={() => onItemClickHandle(el)}
                        variant={'ghost'} size={'iconFit'} className={'place-self-center p-[0.375rem]'}>
                        <ThreeDotsVertical size={20} stroke={Colors.foreground}/>
                    </Button>
                </TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}