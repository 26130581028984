import {produce} from "immer";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {CustomItem} from "src/utils/types/structures/customItem";
import {Item} from "src/utils/types/structures/item";
import {OrderItem} from "src/utils/types/structures/orderItem";
import {getExtendedItemName} from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemName";
import {isCustomItemsEqual} from "src/utils/zustandStores/orderDetailsInventory/utils/isCustomItemEqual";
import {create} from 'zustand'

export type ExtendedOrderItem = {
    isSelected: boolean
    item: OrderItem | CustomItem
    name: string
}

interface State {
    previousExtendedItems: ExtendedOrderItem[]
    currentExtendedItems: ExtendedOrderItem[]
    isAnySelected: boolean
    items: Item[]
}

interface Actions {
    setItems: (item: Item[]) => void
    initExtendedItems: (orderItems: OrderItem[], customItems: CustomItem[]) => void
    setCurrentExtendedItems: (extendedOrderItems: ExtendedOrderItem[]) => void

    onExtendedItemCheckChange: (extendedOrderItem: ExtendedOrderItem) => void
    onCheckAllChange: () => void

    onExtendedItemChangeCount: (extendedOrderItem: ExtendedOrderItem, count: number) => void
    onExtendedItemAdd: (item: Item | CustomItem) => void
    onExtendedItemDelete: () => void

    getSortedItemsToRequestLoad(): Promise<{
        customItemsToDelete: CustomItem[],
        currentCustomItems: CustomItem[],
        currentOrderItems: OrderItem[],
        orderItemsToDelete: OrderItem[]
    }>
}

const useOrderInventory = create<State & Actions>(
    (set, get) => ({
        previousExtendedItems: [],
        currentExtendedItems: [],
        isAnySelected: false,
        items: [],

        setItems: (items: Item[]) => {
            set(
                (state) => (
                    {
                        items: items.sort((a, b) => a.name.localeCompare(b.name))
                    }
                )
            )
        },

        initExtendedItems: (orderItems: OrderItem[], customItems: CustomItem[]) => {
            const items = useOrderInventory.getState().items
            const extendedItems: ExtendedOrderItem[] = []

            orderItems.forEach(el => extendedItems.push(
                {
                    name: getExtendedItemName(el, items),
                    item: el,
                    isSelected: false
                }
            ))

            customItems.forEach(el => extendedItems.push(
                {
                    name: el.name,
                    item: el,
                    isSelected: false
                }
            ))

            const sortedItems = extendedItems.sort((a, b) => a.name.localeCompare(b.name))
            set(
                (state) => (
                    {
                        isAnySelected: false,
                        currentExtendedItems: sortedItems,
                        previousExtendedItems: sortedItems,
                    }
                )
            )
        },

        setCurrentExtendedItems: (extendedOrderItems: ExtendedOrderItem[]) => {
            set(
                (state) => (
                    {
                        currentExtendedItems: extendedOrderItems.sort((a, b) => a.name.localeCompare(b.name)),
                    }
                )
            )
        },

        onExtendedItemCheckChange: (extendedOrderItem: ExtendedOrderItem) => {
            const updatedItems = useOrderInventory
                .getState().currentExtendedItems
                .map(el => {
                    const checkedItem = extendedOrderItem.item
                    const elItem = el.item

                    if (isInstanceOf<OrderItem>(checkedItem, 'itemId')) {
                        if (isInstanceOf<OrderItem>(elItem, 'itemId') && (elItem.itemId === checkedItem.itemId)) {
                            const updatedItem: ExtendedOrderItem = {
                                name: el.name,
                                item: el.item,
                                isSelected: !el.isSelected
                            }
                            return updatedItem
                        } else return el

                    } else {
                        if (isInstanceOf<CustomItem>(elItem, 'description') && isCustomItemsEqual(checkedItem, elItem)) {
                            const updatedItem: ExtendedOrderItem = {
                                name: el.name,
                                item: el.item,
                                isSelected: !el.isSelected,
                            }
                            return updatedItem
                        } else return el
                    }
                })

            set(
                (state) => (
                    {
                        currentExtendedItems: updatedItems,
                        isAnySelected: updatedItems.filter(el => el.isSelected).length > 0
                    }
                )
            )
        },

        onCheckAllChange: () => {
            const isAnySelected = useOrderInventory.getState().isAnySelected
            const extendedOrderItems = useOrderInventory.getState().currentExtendedItems

            if (isAnySelected) {
                const updatedItems = extendedOrderItems.map(el => {
                    const item: ExtendedOrderItem = {
                        name: el.name,
                        item: el.item,
                        isSelected: false
                    }
                    return item
                })

                set(
                    (state) => (
                        {
                            currentExtendedItems: updatedItems,
                            isAnySelected: false
                        }
                    )
                )
            } else {
                const updatedItems = extendedOrderItems.map(el => {
                    const item: ExtendedOrderItem = {
                        name: el.name,
                        item: el.item,
                        isSelected: true
                    }
                    return item
                })

                set(
                    (state) => (
                        {
                            currentExtendedItems: updatedItems,
                            isAnySelected: true
                        }
                    )
                )
            }
        },

        onExtendedItemChangeCount: (extendedOrderItem: ExtendedOrderItem, count: number) => {
            const currentExtendedItems: ExtendedOrderItem[] = useOrderInventory.getState().currentExtendedItems
            const item = extendedOrderItem.item

            if (item.id) {

                const updatedItems = currentExtendedItems
                    .map(el => {
                        if (item.id === el.item.id) {
                            const updatedItem: ExtendedOrderItem = produce(el, draft => {
                                draft.item.count = count
                            })
                            return updatedItem
                        } else return el
                    })

                set(
                    (state) => (
                        {
                            currentExtendedItems: updatedItems,
                        }
                    )
                )
            } else {
                if (isInstanceOf<OrderItem>(item, 'itemId')) {
                    const updatedItems = currentExtendedItems
                        .map(el => {
                            if (isInstanceOf<OrderItem>(el.item, 'itemId') && (el.item.itemId === item.itemId) && !el.item.id) {
                                const updatedItem: ExtendedOrderItem = produce(el, draft => {
                                    draft.item.count = count
                                })
                                return updatedItem
                            } else return el
                        })

                    set(
                        (state) => (
                            {
                                currentExtendedItems: updatedItems,
                            }
                        )
                    )
                } else {
                    const updatedItems = currentExtendedItems
                        .map(el => {
                            if (isInstanceOf<CustomItem>(el.item, 'description') && isCustomItemsEqual(el.item, item) && !el.item.id) {
                                const updatedItem: ExtendedOrderItem = produce(el, draft => {
                                    draft.item.count = count
                                })
                                return updatedItem
                            } else return el
                        })

                    set(
                        (state) => (
                            {
                                currentExtendedItems: updatedItems,
                            }
                        )
                    )
                }
            }
        },

        onExtendedItemAdd: (item: Item | CustomItem) => {
            const currentExtendedItems = useOrderInventory.getState().currentExtendedItems
            const items = useOrderInventory.getState().items

            if (isInstanceOf<Item>(item, 'category')) {
                const repeatedOrderItem = currentExtendedItems.filter(el => isInstanceOf<OrderItem>(el.item, 'itemId') && el.item.itemId === item.id);
                if (repeatedOrderItem.length > 0) {
                    const updatedItems = currentExtendedItems.map(el => {
                        if (isInstanceOf<OrderItem>(el.item, 'itemId')) {
                            if (item.id === el.item.itemId) {
                                const updatedItem: ExtendedOrderItem = produce(el, draft => {
                                    draft.item.count = el.item.count + 1
                                })
                                return updatedItem
                            } else {
                                return el
                            }
                        } else {
                            return el
                        }
                    })

                    set(
                        (state) => (
                            {
                                currentExtendedItems: updatedItems
                            }
                        )
                    )
                } else {
                    const orderItem: OrderItem = {
                        id: null,
                        itemId: item.id,
                        count: 1,
                        originMetadata: {},
                        destinationMetadata: {}
                    }

                    const newExtendedOrderItem: ExtendedOrderItem = {
                        isSelected: false,
                        name: getExtendedItemName(orderItem, items),
                        item: orderItem
                    }

                    set(
                        (state) => (
                            {
                                currentExtendedItems: [newExtendedOrderItem, ...currentExtendedItems],
                            }
                        )
                    )
                }
            } else {
                const repeatedCustomItem = currentExtendedItems.filter(el => isInstanceOf<CustomItem>(el.item, 'description') && isCustomItemsEqual(el.item, item));
                if (repeatedCustomItem[0]) {
                    const updatedItems = currentExtendedItems.map(el => {
                        if (isInstanceOf<CustomItem>(el.item, 'description')) {
                            if (isCustomItemsEqual(el.item, item)) {
                                const updatedItem: ExtendedOrderItem = produce(el, draft => {
                                    draft.item.count = el.item.count + item.count
                                })
                                return updatedItem
                            } else return el
                        } else return el
                    })

                    set(
                        (state) => (
                            {
                                currentExtendedItems: updatedItems
                            }
                        )
                    )
                } else {
                    const newCustomItem: ExtendedOrderItem = {
                        name: item.name,
                        item: item,
                        isSelected: false,
                    }

                    set(
                        (state) => (
                            {
                                currentExtendedItems: [newCustomItem, ...currentExtendedItems],
                            }
                        )
                    )
                }
            }
        },

        onExtendedItemDelete: () => {
            const uncheckedItems = useOrderInventory.getState().currentExtendedItems.filter(el => !el.isSelected);
            set(
                (state) => (
                    {
                        currentExtendedItems: uncheckedItems,
                        isAnySelected: false
                    }
                )
            )
        },

        getSortedItemsToRequestLoad: async () => {
            const currentExtendedItems = useOrderInventory.getState().currentExtendedItems
            const previousExtendedItems = useOrderInventory.getState().previousExtendedItems

            const currentOrderItems: OrderItem[] = []
            const currentCustomItems: CustomItem[] = []

            const previousOrderItems: OrderItem[] = []
            const previousCustomItems: CustomItem[] = []

            currentExtendedItems.forEach(el => {
                const item = el.item
                if (isInstanceOf<OrderItem>(item, 'itemId')) {
                    currentOrderItems.push(item)
                } else currentCustomItems.push(item)
            })

            previousExtendedItems.forEach(el => {
                const item = el.item
                if (isInstanceOf<OrderItem>(item, 'itemId')) {
                    previousOrderItems.push(item)
                } else previousCustomItems.push(item)
            })

            const currentOrderItemsToCompare = currentOrderItems.filter(el => el.id !== null)
            const currentCustomItemsToCompare = currentCustomItems.filter(el => el.id !== null)

            const orderItemsToDelete = previousOrderItems.filter(item => currentOrderItemsToCompare.indexOf(item) < 0)
            const customItemsToDelete = previousCustomItems.filter(item => currentCustomItemsToCompare.indexOf(item) < 0)

            return {
                currentOrderItems: currentOrderItems,
                currentCustomItems: currentCustomItems,
                orderItemsToDelete: orderItemsToDelete,
                customItemsToDelete: customItemsToDelete
            }
        },
    }),
)

export default useOrderInventory;