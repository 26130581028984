import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export type ImageUrl = { url: string, id: string }

export async function getURL(file: File, orderId: string) {

    const request = {
        orderId: orderId,
        metadata: '',
        filename: file.name
    }

    const {token, error} = useTokenStore.getState().getCurrentToken()

    if (error) return error
    else try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/file/`), {
            method: 'POST',
            headers: {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(request)
        })

        if (res.ok) {
            const url: ImageUrl = await res.json()
            return url
        } else return await getDefinedError(res)

    } catch (e) {
        return otherError
    }
}