import {toast} from "src/components/useToast";
import {ErrorMessage} from "src/utils/types/errorMessage";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export const handleResponseError = (errorMessage: ErrorMessage, unauthorisedAction?: () => void) => {
    toast({
        variant: 'destructive',
        title: 'Error',
        description: `${errorMessage.message}`
    })

    switch (errorMessage.status) {
        case ('400'): {
            if (errorMessage.message === 'Access Denied') {
                useTokenStore.getState().resetToken()
                if (unauthorisedAction) unauthorisedAction()
            }
            return;
        }

        case ('403'): {
            if (unauthorisedAction) unauthorisedAction()
            return
        }

        case ('401'): {
            useTokenStore.getState().resetToken()
            if (unauthorisedAction) unauthorisedAction()
            return;
        }

        default: {
            if (unauthorisedAction) unauthorisedAction()
            return
        }
    }
}