import moment from "moment";
import {Badge} from "src/components/badge";
import {Card} from "src/components/card";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import getPropertyType from "src/utils/stringHandlers/enumToStringHandlers/getPropertyType";
import {getAddressIndexOff} from "src/utils/stringHandlers/getAddressIndexOff";
import getFixedToZeroDecimalNumber from "src/utils/stringHandlers/getFixedToZeroDecimalNumber";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import SearchOrderContent from "../../layout/popUps/sheet/content/orderSearch/searchOrderContent";

interface Props {
    order: OrderSearch
}

export default function OrderCard({order}: Props) {
    const {open} = useSheet()

    return <Card
        className={'w-full flex flex-col gap-3 py-[11px] px-[11px] cursor-pointer '}
        onClick={() => open('Load details', <SearchOrderContent order={order}/>)}>
        <h3 className={'text-[#222222] !text-[18px] leading-[24px] font-bold'}>${getFixedToZeroDecimalNumber(order.rate, 2)}</h3>

        <div className={'flex gap-2 place-items-center'}>
            <DotIcon size={8} className={'fill-foreground'}/>
            <div className={'flex-1 flex gap-2 justify-between'}>
                <p className={'flex-1 text-[#222222] text-[14px] leading-[20px] font-medium'}>{getAddressIndexOff(order.originAddress)}</p>
                <span
                    className={'flex-none text-[#717171] text-[14px] leading-[20px] font-normal'}>{moment(order.pickupDate).format('MMMM D')}</span>
            </div>
        </div>

        <div className={'flex gap-2 place-items-center'}>
            <div className={`flex-none fill-foreground`}>
                <SquareIcon size={8} className={'fill-foreground'}/>
            </div>

            <div className={'flex-1 flex gap-2 justify-between'}>
                <span
                    className={'flex-1 text-[#222222] text-[14px] leading-[20px] font-medium'}>{getAddressIndexOff(order.destinationAddress)}</span>
                <span
                    className={'flex-none text-[#717171] text-[14px] leading-[20px] font-normal'}>{order.deliveryDate}</span>
            </div>

        </div>

        <div className={'flex gap-2'}>
            <Badge className={'text-foreground text-xs font-medium h-fit'}>{getPropertyType(order.property)}</Badge>
            <Badge
                className={'text-foreground text-xs font-medium h-fit'}>{getFixedToZeroDecimalNumber(order.distance)} mi</Badge>
            <Badge
                className={'text-foreground text-xs font-medium h-fit'}>{getFixedToZeroDecimalNumber(order.volume)} cu.ft</Badge>
        </div>
    </Card>
}