import {zodResolver} from "@hookform/resolvers/zod";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem} from "src/components/form";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import * as z from "zod";
import {SpinnerIcon} from "../../../../../../../../media/icons/custom/spinnerIcon";
import {handleResponseError} from "../../../../../../../../utils/errorHandlers/handleResponseError";
import {useNavigate} from "react-router-dom";
import {isInstanceOf} from "../../../../../../../../utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "../../../../../../../../utils/types/errorMessage";
import {OrderDetails} from "../../../../../../../../utils/types/structures/orderDetails";
import editSingleItemSchema from "../../../../../../../../utils/zodSchemas/edit-single-item-schema";
import useOrderInventory, {
    ExtendedOrderItem
} from "../../../../../../../../utils/zustandStores/orderDetailsInventory/orderInventory";
import {CustomItem} from "../../../../../../../../utils/types/structures/customItem";
import {OrderItem} from "../../../../../../../../utils/types/structures/orderItem";
import {getOrderItems} from "../../../../../../../../utils/apiCalls/orderDetails/orderItems/getOrderItems";
import {getOrderCustomItems} from "../../../../../../../../utils/apiCalls/orderDetails/customItems/getOrderCustomItems";
import {
    getExtendedItemDestinationMetadata,
    getExtendedItemOriginMetadata
} from "../../../../../../../../utils/getItemMetadata";
import {Input} from "../../../../../../../../components/input";
import {Textarea} from "../../../../../../../../components/textArea";
import {atOriginConditions, atOriginNotesPackingContent} from "./carrier-notes-predefined-content";
import {createDestinationMetadata} from "../../../../../../../../utils/apiCalls/metadata/createDestinationMetadata";

interface Props {
    data: ExtendedOrderItem
    order: OrderDetails
}

export default function EditMetadataAtDelivery({data, order}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);

    const {close} = useSheet()
    const navigate = useNavigate()
    const {initExtendedItems} = useOrderInventory();

    const form = useForm<z.infer<typeof editSingleItemSchema>>({
        defaultValues: {
            count: data.item.count,
            notesAtOrigin: getExtendedItemOriginMetadata(data),
            notesAtDestination: getExtendedItemDestinationMetadata(data),
        },
        resolver: zodResolver(editSingleItemSchema),
        shouldFocusError: false,
    });

    async function onSubmit(values: z.infer<typeof editSingleItemSchema>) {
        setLoading(true)
        const notesAtDestination = values.notesAtDestination

        if (data.item.id && notesAtDestination) {
            const {data: datatype, error} = await createDestinationMetadata(data.item.id as string, notesAtDestination)
            if (datatype) console.log(datatype)
            if (error) console.log(error)

            let currentOrderItems: OrderItem[] = []
            let currentCustomItems: CustomItem[] = []

            const orderItems = await getOrderItems(order.id);
            if (isInstanceOf<ErrorMessage>(orderItems, 'message')) {
                handleResponseError(orderItems, () => navigate('/'))
            } else currentOrderItems = orderItems

            const customItems = await getOrderCustomItems(order.id);
            if (isInstanceOf<ErrorMessage>(customItems, 'message')) {
                handleResponseError(customItems, () => navigate('/'))
            } else currentCustomItems = customItems

            initExtendedItems(currentOrderItems, currentCustomItems)
        }

        setLoading(false)
        close()
    }

    return <div className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>

        <Form {...form}>
            <form
                autoComplete={"off"}
                className="flex-1 flex flex-col gap-4"
                onSubmit={form.handleSubmit(onSubmit)}
            >

                <section className={'flex-1 flex flex-col gap-6 px-6 pt-6 relative'}>

                    <div className={'flex flex-row items-center gap-4'}>
                        <div className={'flex-1 flex flex-col gap-2 h-[72px]'}>
                            <p className={'font-medium text-foreground text-[14px] leading-[16px]'}>Item name</p>
                            <Input
                                placeholder={"Item name"}
                                disabled={true}
                                value={data.name}
                                className={`text-base text-foreground w-full h-[48px]`}/>
                        </div>

                        <div className={'flex-none flex flex-col gap-2 w-[64px] h-[72px]'}>
                            <p className={'font-medium text-foreground text-[14px] leading-[16px]'}>Quantity</p>
                            <Input
                                placeholder={"Item name"}
                                disabled={true}
                                value={data.item.count}
                                className={`text-center text-base text-foreground w-full h-[48px]`}/>
                        </div>
                    </div>

                    {
                        data.item.originMetadata.NOTE !== undefined && <div className={'flex flex-col gap-1'}>
                            <p className={'text-foreground text-[14px] leading-[16px] font-medium'}>Notes at Origin</p>
                            <p className={'text-[#717171] text-[14px] leading-[16px] font-normal mb-1'}>
                                {getExtendedItemOriginMetadata(data)}
                            </p>
                        </div>
                    }


                    <div className={'flex flex-col gap-1'}>
                        <p className={'text-foreground text-[14px] leading-[16px] font-medium'}>Notes at Delivery</p>
                        <p className={'text-[#717171] text-[14px] leading-[16px] font-normal mb-1'}>
                            {'Describe the item\'s condition, packing, or other details, or click below for quick copy-paste options.'}
                        </p>

                        <FormField
                            control={form.control}
                            name="notesAtDestination"
                            render={({field}) => (
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            {...field}
                                            placeholder={'Leave comments here...'}
                                            className={`h-fit min-h-[120px] text-base ${form.getFieldState('notesAtDestination').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} flex-1`}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex flex-col gap-4'}>
                        <section className={'flex flex-col gap-3'}>
                            <p className={'text-foreground text-[14px] leading-[16px] font-medium'}>Packing:</p>
                            <ul className={'flex flex-row flex-wrap gap-2'}>
                                {atOriginNotesPackingContent.map(el =>
                                    <li className={'rounded-full cursor-pointer bg-[#f2f2f2] py-[10px] px-[12px] text-foreground text-[14px] leading-[16px] font-medium'}
                                        onClick={() => {
                                            const currentValue = form.getValues('notesAtDestination')
                                            const shouldPlaceComma = currentValue === undefined || currentValue.length > 0
                                            form.setValue('notesAtDestination', currentValue + `${!shouldPlaceComma ? `${el.value}` : `, ${el.value}`}`)
                                        }}
                                        key={el.title}>
                                        {el.title}
                                    </li>)}
                            </ul>
                        </section>

                        <section className={'flex flex-col gap-3'}>
                            <p className={'text-foreground text-[14px] leading-[16px] font-medium'}>Conditions:</p>
                            <ul className={'flex flex-row flex-wrap gap-2'}>
                                {atOriginConditions.map(el =>
                                    <li className={'rounded-full cursor-pointer bg-[#f2f2f2] py-[10px] px-[12px] text-foreground text-[14px] leading-[16px] font-medium'}
                                        onClick={() => {
                                            const currentValue = form.getValues('notesAtDestination')
                                            const shouldPlaceComma = currentValue === undefined || currentValue.length > 0
                                            form.setValue('notesAtDestination', currentValue + `${!shouldPlaceComma ? `${el.value}` : `, ${el.value}`}`)
                                        }}
                                        key={el.title}>
                                        {el.title}
                                    </li>)}
                            </ul>
                        </section>
                    </div>
                </section>

                <section
                    className={`flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4`}>
                    <Button size={'lg'}
                            type={'button'}
                            disabled={isLoading}
                            variant={'outline'}
                            className={'w-full px-5 text-center text-foreground text-[16px] leading-[20px] font-medium'}
                            onClick={close}>Cancel
                    </Button>

                    <Button
                        size={'lg'}
                        variant={'default'}
                        type={'submit'}
                        disabled={isLoading}
                        className={'w-full text-base font-medium leading-tight relative'}>
                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                     size={20}/>
                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Save</p>
                    </Button>
                </section>
            </form>
        </Form>
    </div>
}