import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button} from "src/components/button";
import {Form} from "src/components/form";

import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {PropertyType} from "src/utils/enums/addressEnum/propertyType";
import {Address} from "src/utils/types/structures/Address";
import * as z from "zod";
import {SpinnerIcon} from "../../../../../../../../media/icons/custom/spinnerIcon";
import {OrderAddressToEdit, updateOrderAddress} from "../../../../../../../../utils/apiCalls/editOrderAddress";
import {NumberStories} from "../../../../../../../../utils/enums/addressEnum/numberStories";
import {SquareFootage} from "../../../../../../../../utils/enums/addressEnum/squareFootage";
import {Access} from "../../../../../../../../utils/enums/addressEnum/access";
import {UnitSize} from "../../../../../../../../utils/enums/addressEnum/unitSize";
import {PropertySize} from "../../../../../../../../utils/enums/addressEnum/propertySize";
import {handleResponseError} from "../../../../../../../../utils/errorHandlers/handleResponseError";
import routes from "../../../../../../../../utils/defaults/routes";
import {useNavigate} from "react-router-dom";
import usePlacesAutocomplete from "use-places-autocomplete";
import googleAutocompleteSchema from "../../../../../../../../utils/zodSchemas/googleAutocompleteSchema";
import GoogleAutoComplete
    from "../../../../../../../../utils/autocomplete/customerAddressAndProperty/googleAutoComplete";
import {PropertyUI} from "../../../../../../../../utils/autocomplete/customerAddressAndProperty/propertyUI";
import {AddressDirection} from "../../../../../../../../utils/autocomplete/addressDirection";
import ManualAddrInput from "../../../../../../../../utils/autocomplete/customerAddressAndProperty/manualAddrInput";
import {getOrder} from "../../../../../../../../utils/apiCalls/orderDetails/getOrder";
import {isInstanceOf} from "../../../../../../../../utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "../../../../../../../../utils/types/errorMessage";
import {OrderDetails} from "../../../../../../../../utils/types/structures/orderDetails";

interface Props {
    address: Address
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function ChangeOrderAddress({address, setOrder, order}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const {close} = useSheet()
    const navigate = useNavigate()

    const onMutateAddress = async () => {
        const res = await getOrder(order.id)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {
            setOrder(res)
        }
    }

    const [isManualAddressFormActive, setManualAddressFormActive] =
        useState<boolean>(false);

    const form = useForm<z.infer<typeof googleAutocompleteSchema>>({
        defaultValues: {
            access: address && address.access ? address.access : "",
            floorNumber: address && address.floorNumber ? address.floorNumber : "",
            googleMapAddress: address.address ?? '',
            manualApt: "",
            manualCity: "",
            manualCountry: "",
            manualState: "",
            manualStreetAddress: "",
            manualZip: "",

            numberStories: address && address.numberStories ? address.numberStories : "",
            propertySize: address && address.propertySize ? address.propertySize : "",
            propertyType: address && address.propertyType ? address.propertyType : "",
            squareFootage: address && address.squareFootage ? address.squareFootage : "",
            unitNumber: address && address.unitNumber ? address.unitNumber : "",
            unitSize: address && address.unitSize ? address.unitSize : "",
        },
        resolver: zodResolver(googleAutocompleteSchema),
        shouldFocusError: false,
    });

    const {clearSuggestions, setValue, suggestions, value} =
        usePlacesAutocomplete({
            debounce: 300,
            requestOptions: {
                componentRestrictions: {country: "us"},
                types: ["address"],
            },
        });


    useEffect(() => {
        if (address) {
            setValue(address.address ? address.address : "", false);
        }
    }, [address]);

    async function onSubmit(values: z.infer<typeof googleAutocompleteSchema>) {
        if (order.id && address.direction) {
            setLoading(true)
            const updatedAddress: OrderAddressToEdit = {
                address: values.googleMapAddress,
                orderId: order.id,
                unitNumber: values.unitNumber ?? '',
                direction: address.direction,
                floorNumber: values.floorNumber ?? '',
                propertyType: values.propertyType as PropertyType,
                access: values.access as Access,


                numberStories: values.numberStories.length === 0 ? null : values.numberStories as NumberStories,
                squareFootage: values.squareFootage.length === 0 ? null : values.squareFootage as SquareFootage,
                unitSize: values.unitSize.length === 0 ? null : values.unitSize as UnitSize,
                propertySize: values.propertySize.length === 0 ? null : values.propertySize as PropertySize,
            }

            const {data, error} = await updateOrderAddress(updatedAddress);

            if (data) {
                await onMutateAddress()
                close()
            }

            if (error) handleResponseError(error, () => navigate(routes.login))

            setLoading(false)
        } else console.error('id not found')
    }

    return <div className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>

        <Form {...form}>
            <form
                autoComplete={"off"}
                className="flex-1 flex flex-col gap-4"
                onSubmit={form.handleSubmit(onSubmit)}
            >

                <section className={'flex-1 flex flex-col gap-4 px-6 pt-6 relative'}>
                    <section
                        className={`flex flex-col gap-6 ${isManualAddressFormActive ? "hidden" : "block"}`}
                    >
                        {address.direction && <GoogleAutoComplete
                            clearSuggestions={clearSuggestions}
                            direction={address.direction as AddressDirection}
                            form={form}
                            isManualAddressFormActive={isManualAddressFormActive}
                            label={address.direction === 'ORIGIN' ? 'Origin address' : 'Destination address'}

                            setManualAddressFormActive={setManualAddressFormActive}
                            setValue={setValue}
                            suggestions={suggestions}
                            value={value}
                        />}
                        <p className={'text-[#222222] text-[18px] leading-[24px] font-medium'}>Property details</p>
                        <PropertyUI isDashboard={false} address={address} form={form}/>
                    </section>

                    <ManualAddrInput
                        form={form}
                        isManualAddressFormActive={isManualAddressFormActive}
                        setManualAddressFormActive={setManualAddressFormActive}
                        setValue={setValue}
                    />
                </section>

                <section
                    className={`${isManualAddressFormActive && 'hidden'} flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4`}>
                    <Button size={'lg'}
                            type={'button'}
                            variant={'outline'}
                            className={'w-full px-5 text-[#222222] text-center text-[16px] leading-[20px] font-medium'}
                            onClick={close}>Cancel</Button>
                    <Button
                        size={'lg'}
                        variant={'default'}
                        type={'submit'}
                        className={'w-full text-base font-medium leading-tight relative'}>
                        <SpinnerIcon className={`${isLoading ? 'visible' : 'invisible'} fill-marcoWhite absolute`}
                                     size={20}/>
                        <p className={`${isLoading ? 'invisible' : 'visible'} px-5 text-center text-white text-[16px] leading-[20px] font-medium`}>Update</p>
                    </Button>
                </section>
            </form>
        </Form>
    </div>
}